#about {
  background-color: rgba(218,221,237,1);
  position: relative;
}
.about-ctn {
  overflow: hidden;
  background-color: rgb(242, 243, 245);
  width: 87vw;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 70px;
}
.about-text-ctn {
  width: 80vw;
  margin: auto;
}
.about-text,.about-subtitle,.about-header{
  color: #363636;
}
.about-header{
  text-align: center;
  font-size: 20px;
  margin: 28px;
}
.about-text{
  white-space: pre-wrap;
  font-size: 18px;
  line-height: 2;
}
.abt-txt-title{
  color: #f1b93f;
  font-weight: 600;
  font-size: 20px;
  display: inline-block;
  margin-bottom: 8px;
  border-bottom: 2px solid #f1b93f;
  padding-right: 10px;
}
.textbox-left{
  display: flex;
  justify-content: center;
}
.logoImg{
  width: 150px;
  border-radius: 20px;
}
.aboutPaw{
  color: #f1b93f;
  font-size: 10px;
  margin-right: 3px;
}
.textbox-right{
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}
.about-text-box{
  display: flex;
  flex-direction: column;
}
/* Animation.css */
.about-header.animate__fadeInUp {
  animation-delay: 0.5s
}
.about-line.animate__fadeInRight {
  animation-delay: 0.5s
}

@media (min-width: 768px) {
  .textbox-right{
    margin-left: 3vw;
    padding: 15px;
    margin-top: 0px;
  }
  .about-ctn {
    width: 78vw;
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .about-text-ctn {
    width:60vw;
    margin: auto;
  }
  .about-title-ctn{
    display: flex;
    align-items: flex-end;
  }
  .about-header{
    color: #6E5858;
    font-size: 3.5vw;
    margin: 1vh;
  }
  .about-line {
    border-top: 0.5vh solid #f1b93f;
    margin-bottom: 6vh;
  }
  /* .about-line:nth-child(4){
    margin-top: 6vh;
  } */
  .about-text-box{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: auto;
    width: 90%;
  }
  .logoImg{
    width: 200px;
  }
  .arrowsSVG {
    margin: 4vh 0;
    width: 9vw;
    float: right;
  }
  .about-text{
    font-size: 18px;
  }
  .abt-txt-title{
    margin-top: 0px;
    font-size: 20px;
    padding-right: 10px;
    border-bottom: none;
  }
  .aboutPaw{
  font-size: 18px;
  margin: 0 5px;
  }
}