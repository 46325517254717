nav{
  background-color: #ffe2e2;
  position: relative;
}
.navPaw{
  font-size: 15px;
  margin-right: 5px;
}
.bars {
  margin:0 10px;
  width: 20px !important;
  cursor: pointer;
  transition: all 2s;
  color: white;
}
.bars:hover{
  transform: rotate(1080deg);
}
.navItem {
  display: flex;
  align-items: center;
}
.nav-links{
  position: fixed;
  background-color: rgb(148, 200, 235);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline-start: 0;
  width: 100vw;
  height: 100vh;
  top:-150%;
  transition: all 1.1s;
  z-index: 900;
}
.nav-links.open{
  z-index: 900;
  line-height: 1.1;
  margin: auto;
  left: 0%;
  top:0%;
}
.nav-ctn{
  position: relative;
  width: 100vw;
  height: 100vh;
}
.navlinks-border{
  border: 5px solid white;
  background-color: rgb(148, 200, 235);
  height: 75vh;
  font-size: 20px;
  list-style: none;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5vw;
  width: 80vw;
  border-radius: 5px;
  /* centered the div */
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.navBtnBlock{
  background-color: rgb(148, 200, 235);
  height: 50vh;
  width: 100vw;
  position: absolute;
  bottom: 0;
  border-top: 5px solid white;
}
.navlinks-border>li:hover {
  cursor: crosshair;
  color: rgb(42, 117, 230);
}
ul{
  margin: 0;
}
.nav-links li {
  margin: 2.5vh;
}
.hambuger-ctn{
  width: 100%;
  position: fixed;  
  transition: 300ms transform ease-out;
  will-change: transform;
  top: -0;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  background: rgba(148, 200, 235);
}


/* ScrollOut */
/* scrolling down */
html[data-scroll-dir-y='1'] .hambuger-ctn {
  transform: translateY(-100%); 
}
/* scrolling up */
html[data-scroll-dir-y='-1'].hambuger-ctn  {
  transform: translateY(200%);
}

@media (min-width: 768px){
  .bars {
    right: 0;
    margin:0 30px;
    width: 25px !important;
  }
  .navPaw{
    font-size: 18px;
    margin-right: 8px;
  }
  .nav-links{
    font-size: 3.5vh;
    font-weight: 500;
  }
  .navlinks-border{
    width: auto;
    padding: 0 8vw;
  }
}
