.infotitle {
  font-size: 18px;
  margin: 5px;
  text-decoration: underline;
  padding: 2px;
  font-weight: 800;
}
.infoBlockContainer {
  margin: 10% 0;
  width: 70vw;
}
.infoImg {
  width: 60vw;
}
.block_center,.infoBlockContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.block_info{
  color:#444f59;
}
.blockText{
  font-size: 18px;
}
/* ScrollOut */
/* no show before triggered by ScrollOut */
.fLeft,.fRight{
  opacity: 0;
}

/* visible while triggering by ScrollOut */
.fLeft.animate__fadeInLeft,.fRight.animate__fadeInRight{
  opacity: 1;
}

@media (min-width: 768px){
  .block_container div:nth-child(even) img {
  order:2;
  }
  .infoBlockContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0;
    justify-content: center;
    width: auto;
    border-top: 2px solid #746835;
    border-bottom: 2px solid #746835;
  }
  .infoImg {
    width: 20vw;
  }
  .block_container{
    margin:5% 0;
  }
  .block_info{
    width:29vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .infotitle {
    font-size: 22px;
    margin-bottom: 20px;
    padding: 4px 10px;
    background-color: rgb(214, 214, 245);
    margin: 5px auto;
    border-radius: 5px;
    text-decoration: none;
  }
  .blockText{
    font-size: 18px;
    margin: 0 2vw;
  }
}