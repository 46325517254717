#awards{
  background: rgb(243,217,218);
  background: linear-gradient(0deg, rgba(243,217,218,1) 17%, rgba(218,221,237,1) 100%);
  overflow: hidden;
}
.awardImg-ctn{
  position: relative;
  overflow: hidden;
}
.awardsImg01,.awardsImg02 {
  display: block;
  max-width: 65%;
  width: auto;
  height: auto;
  margin: auto;
}
.awTextBox {
  background-color: white;
  width: 100%;
  text-align: center;
  padding: 10vh 0;
}
.awText {
  white-space: pre-wrap;
  line-height: 220%;
  font-size: 16px;
  color: #6E5858;
  /* no show before triggered by ScrollOut */
  opacity: 0;
}
.awardsBackground{
  width: 87vw;
  margin: auto;
  background-color: rgb(242, 243, 245);
  padding: 60px 0;
}
.awardsContent {
  background-color: #dadce8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0px 5px 10px #a0a7b1;
}
.pn {
  background-color: orange;
  display: inline-block;
  color: white;
  border-radius: 5px;
  padding: 0 10px;
  margin-top: 15px;
}
/* Color Sliding Block */
.awardBlockL,.awardBlockR {
  background-color: #0e3150;
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  animation-delay: 0.2s;
}
.awardBlockL2,.awardBlockR2 {
  background-color: #6dc9c8;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  animation-delay: 0.3s;
}
/* visible while triggering by ScrollOut */
.awText.animate__fadeIn{
  opacity: 1;
}


@media (min-width: 768px) {
  .awardsContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 65vw;
    padding: 100px 0;
  }
  .awardsBackground{
  width: 78vw;
  padding: 150px 0 150px 0;
}
  .awText {
    font-size: 16px;
  }
  .awTextBox{
    margin:1%;
    max-width: 29vw;
    border-radius: 20px;
    box-shadow: 0px 5px 10px #8f96a0;
  }
  .awardsImg01,.awardsImg02,.awardImg-ctn {
    max-width: 15vw;
    min-width: 12vw;
    border-radius: 10px;
  }
}