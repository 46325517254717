body, html {
  width: 100%;
  height: 100vh;
  border:0;
  background-color: #ffe2e2;
}
* {
  box-sizing: border-box;
  margin:0;
  padding:0;
}

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Roboto&family=Noto+Sans+TC:wght@300&display=swap');
.roboto {
  font-family: 'Roboto', sans-serif;
}
.abril-fatface {
  font-family: 'Abril Fatface', cursive;
}
/* chinese font */
.noto {
  font-family: 'Noto Sans TC', sans-serif;
}
.text_center{
  text-align: center;
}
.underline {
  text-decoration: underline;
}
@media (min-width: 768px) {

}