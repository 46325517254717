/* *{
  border: 0.5px solid red;
} */
/* Banner_L */
.banner{
  height: 10vh;
  overflow: hidden;
  position: relative;
}
.bannerImg {
  position: absolute;
  width: 100%;
  top:-300%;
  background-size:cover;
}

/* Banner_M */
.bannerM{
  width: 100%;
}
.bannerM_ctn{
  overflow: hidden;
  width: 87vw;
  margin: auto;
  height: 100px;
  position: relative;
}
.bannerM_background{
  width: 1000px;
  position: absolute;
  top: -300%;
  left: -10%;
}

 /* ThreeImg */
.threeImg{
  display: none;
}

@media (min-width: 768px) {
  /* Banner_L */
  .banner{
    height: 300px;
  }
  .bannerImg {
    top:-50%;
  }
  /* Banner_M */
  .bannerM_background{
    width: 150vw;
    margin: auto;
    top: -150%;
    left: -10%;
  }
  .bannerM_ctn{
    width: 78vw;
    height: 300px;
  }

  /* ThreeImg */
  .threeImg{
    display: initial;
  }
  .threeImg-ctn{
    background-color: #dadce8;
    background: linear-gradient(0deg,rgba(183,188,212,1) 0%, rgba(218,220,232,1) 50%, rgba(183,188,212,1) 100%);
    width: 78vw;
    margin: auto;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    border: 20px solid rgb(242, 243, 245);
  }
  .threeImg-ctn img{
    width: auto;
    height: auto;
    max-width: 25%;
    margin: 1vw;
    display: block;
    border: 6px solid white;
    box-shadow: 5px 10px 10px grey;
    /* no show before triggered by ScrollOut */
    opacity: 0;
  }
  /* ScrollOut & Animation.css */
  .threeImg_individual.animate__flipInX {
    animation-delay: 0.3s;
    /* visible while triggering by ScrollOut */
    opacity: 1;
  }
}