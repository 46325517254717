#contact {
  background-color: #302d53;
}
footer{
  color: rgb(197, 195, 195);
  background-color: rgb(25, 25, 34);
  padding: 30px 10vw;
  font-size: 12px;
  line-height: 22px;
}
.contact-info{
  color: white;
  padding: 20px;
  font-size: 14px;
  line-height: 30px;
}
.fbIcon:hover{
  color: #ebaaac;
  transition: all 0.8s;
}
.fbIcon{
  margin-right: 10px;
  color: rgb(101, 166, 219);
  display: flex;
  justify-content: center;
}
.fb{
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  #contact{
    border: 2px solid #302d53;
  }
  .contact-info{
    padding: 7vw;
    font-size: 18px;
    display: flex;
    justify-content: space-around;
    line-height: 40px;
  }
  .contactR{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  footer{
    font-size: 16px;
    line-height: 24px;
  }
  .fb{
    margin: 5px 0;
  }
}
