#important{
  background-color: white;
  padding-top: 50px;
  padding-bottom: 80px;
  overflow:hidden;
  z-index: -10;
  position: relative;
}
.txt_wrapper {
  font-size: 18px;
  white-space: pre-wrap;
  color: #444f59;
  padding:0 5vw;
  /* line-height: 30px; */
}
.eachDetail{
  margin-bottom: 20px;
}
.rotateY {
  transform: rotateY(180deg);
}
.blockYellow {
  position: absolute;
  height: 40vh;
  width: 200%;
  background-color: #FBDD7D;
  transform:rotate(-20deg);
  margin-left: -30%;
  box-shadow:0px 0px 8px 5px rgb(189, 187, 187); 
  z-index: -5;
}
.blockBlue {
  position: absolute;
  height: 40vh;
  width: 200%;
  background-color: #ffe2e2;
  transform:rotate(20deg);
  margin-left: -30%;
  z-index: -4;
  box-shadow:0px 0px 8px 5px rgb(189, 187, 187); 
}
.yellow01{
  top: 3%;
}
.blue01{
  top: 22%;
}
.yellow02{
  top: 38%;
  z-index: -4;
}
.blue02{
  top: 55%;
}
.yellow03{
  top:70%;
  z-index: -4;
}
.blue03{
  top: 88%;
  z-index: -4;
}
.visitTimeSVG {
  margin: 15px 0;
  width: 60vw;
}
.important_title{
  font-size: 40px;
}
.line_goThrough {
  border-top: 0.3px solid #d1cfcf;
  width: 100vw;
  height: 10px;
  position: relative;
  top: -1.6rem;
  z-index: -1;
}
.line_goThrough_btm{
  border-bottom: 0.3px solid #d1cfcf;
  width: 100vw;
  height: 10px;
  z-index: -1;
}
.blueCat{
  width: 25vw;
  margin: -7vw;
}
.important_wrapper{
  background-color: white;
  border: 0.3px solid #d1cfcf;
  box-shadow:0px 10px 10px grey;
  width:85vw;
  border-bottom: 0.3px solid #d1cfcf;
  margin: auto;
  overflow: hidden;
}
.catL_wrapper{
  position: relative;
}
.catL {
  position: absolute;
  top: -1.8rem;
  z-index: -2;
}
.importantContent .starsky{
  position: absolute;
  z-index: -1;
}
.h_subtitle{
  margin: 20px 0px 8px 15px;
  font-size: 24px;
}
.im_subtitle{
  color:rgb(117, 103, 103);
  font-weight: 800;
  padding: 0 5px;
  line-height: 24px;
  border: 1px solid rgb(182, 171, 171);
  display: inline-block;
  margin: 5px 0 3px 0;
}
.importantContent{
  position: relative;
}
.neko01{
  position: absolute;
  z-index: -3;
}
.neko02{
  position: absolute;
  top: 25%;
  z-index: -1;
}
.neko03{
  position: absolute;
  top: 50%;
  z-index: -1;
}
.neko04{
  position: absolute;
  top: 75%;
  z-index: -1;
}
.neko05{
  position: absolute;
  bottom: 0;
  z-index: -1;
}
hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* no show before triggered by ScrollOut */
.fLeft,.fRight{
  opacity: 0;
}
/* visible while triggering by ScrollOut */
.fLeft.animate__fadeInLeft,.fRight.animate__fadeInRight{
  opacity: 1;
}

@media (min-width: 768px){
  #important{
    padding-top: 100px;
    padding-bottom: 200px;
  }
  .blueCat{
    width: 20vw;
  }
  .important_title{
    font-size: 130px;
    font-weight: 500;
  }
  .line_goThrough{
    top:-4rem;
  }
  .blockYellow {
  height: 55vh;
  box-shadow:0px 0px 18px 18px rgb(189, 187, 187); 
  }
  .blockBlue {
    height: 55vh;
    box-shadow:0px 0px 18px 18px rgb(189, 187, 187); 
  }
  .h_subtitle{
    margin: 30px 0 15px 60px;
    font-size: 50px;
  }
  .txt_wrapper{
    font-size: 18px;
    line-height: 40px;
  }
  .visitTimeSVG {
    width: 22vw;
  }
  .visitTimewrapper{
    display: flex;
  }
  .visitTimeInfo{
    margin-left: 2vw;
    margin-top: 5vh;
  }
  .neko02,.neko05,.neko04{
    opacity: 0;
  }
  .important_wrapper{
    width:60vw;
    padding-bottom: 50px;
  }
  .line_goThrough_btm{
    opacity: 0;
  }
  .catL{
    margin-top: 5vh;
    width:30vw;
    margin-left: -4vw;
    margin-top: -2vh;
  }
  .importantContent .starsky{
    margin-left: 2vw;
    margin-top: 5vh;
  }
  .neko01{
    margin-top: 10vh;
  }
  .im_subtitle{
    padding: 2px 7px;
    margin: 10px 0 3px 0;
    font-size: 20px;
  }
  .eachDetail{
    margin-bottom: 30px;
  }
}