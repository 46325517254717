.swiper-container.gallery {
  width: 100%;
  height: 350px;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide.gallery {
  /* background-size: cover; */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-next,.swiper-button-prev{
  color: rgb(77, 85, 189);
  /* background-color: white; */
  /* border-radius: 5px; */
}
.gallery-top {
  height: 80%;
  width: 100%;
}
.gallery-thumbs {
  box-sizing: border-box;
  padding: 10px 0;
  width: 100%;
  height: 14vh;
}
.gallery-thumbs .swiper-slide {
  height: 100%;
  opacity: 0.4;
}
.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}
.galleryWrapper {
  padding-bottom: 80px;
}

@media (min-width: 768px){
  .swiper-container.gallery {
    height: 60vh;
    width: 50vw !important;
  }
  .swiper-container{
    width: 50vw !important;
  }
  .galleryWrapper {
    padding-bottom: 200px;
  }
}
