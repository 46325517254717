#facilities {
  background: linear-gradient(0deg, rgba(243,217,218,1) 0%, rgba(218,221,237,1) 12%, rgba(218,221,237,1) 89%, rgba(243,217,218,1) 100%);
  padding-bottom: 100px;
  display: block;
}
.facilities-ctn{
  background-color: rgb(242, 243, 245);
  width: 87vw;
  margin: auto;
  overflow: hidden;
  padding-top: 50px;
}
.facilitiesContent{
  width: 100%;
}
.title{
  font-size: 20px;
  text-align: center;
  color: #524217;
}
.facilitiesPaw{
  color: #524217;
  font-size: 15px;
  margin: 5px;
}
.facilities-title{
  display: flex;
  /* border: 1px solid red; */
  align-items: baseline;
  justify-content: center;
}
@media (min-width: 768px) {
  .facilities-ctn{
  width: 78vw;
  padding: 20vh 0;
  }
  .title{
    font-size: 35px;
    font-weight: 800;
  }
  .facilitiesPaw{
    font-size: 25px;
  }
}