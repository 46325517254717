@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Roboto&family=Noto+Sans+TC:wght@300&display=swap);
#contact {
  background-color: #302d53;
}
footer{
  color: rgb(197, 195, 195);
  background-color: rgb(25, 25, 34);
  padding: 30px 10vw;
  font-size: 12px;
  line-height: 22px;
}
.contact-info{
  color: white;
  padding: 20px;
  font-size: 14px;
  line-height: 30px;
}
.fbIcon:hover{
  color: #ebaaac;
  transition: all 0.8s;
}
.fbIcon{
  margin-right: 10px;
  color: rgb(101, 166, 219);
  display: flex;
  justify-content: center;
}
.fb{
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  #contact{
    border: 2px solid #302d53;
  }
  .contact-info{
    padding: 7vw;
    font-size: 18px;
    display: flex;
    justify-content: space-around;
    line-height: 40px;
  }
  .contactR{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  footer{
    font-size: 16px;
    line-height: 24px;
  }
  .fb{
    margin: 5px 0;
  }
}

.ani_gap[data-scroll] {
  transition: height 0.5s;
}
.ani_gap[data-scroll="in"] {
  height: 0px;
}
.ani_gap[data-scroll="out"] {
  height: 150px;
}

#home {
  background: rgb(243,217,218);
  background: linear-gradient(0deg, rgba(243,217,218,1) 83%, rgba(218,221,237,1) 100%);
  position: relative;
  height: 110vh;
  overflow: hidden;
}
.home-ctn{
  height: 100vh;
  display: flex;
  align-items: center;
}
.homeImg{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
}
.neko-house{
  color: #f4a3a4;
  font-size:5vh;
  display: inline-block;
}
.home-house{
  margin-top: -2vh;
}
.home-starsky{
  font-size: 1rem;
  color: #ffe2e2;
  background-color: #f4a3a4;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;   
}
.inverted-triangle{
  display: flex;
  flex-direction: column;
}

/* flashing inverted triangle for scroll down */
.fa-caret-down{
  -webkit-animation: flash 1s infinite;
          animation: flash 1s infinite;
  /* border: 1px solid red; */
  margin: auto;
}
.fa-caret-down:nth-child(1){
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
  width: 25px;
  margin-top: 15px;
}
.fa-caret-down:nth-child(2){
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
  width: 25px;
  margin-top: -25px;
}
.fa-caret-down:nth-child(3){
  -webkit-animation-delay: 1.9s;
          animation-delay: 1.9s;
  width: 25px;
  margin-top: -25px;
}
@-webkit-keyframes flash {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes flash {
  from {opacity: 0;}
  to {opacity: 1;}
}
/* scrollOut */
.home-house[data-scroll="in"] {
 -webkit-animation-delay: 0.5s;
         animation-delay: 0.5s
}
.home-starsky[data-scroll="in"] {
 -webkit-animation-delay: 1s;
         animation-delay: 1s
}
.inverted-triangle[data-scroll="in"] {
 -webkit-animation-delay: 1.5s;
         animation-delay: 1.5s
}

@media (min-width: 768px) {
  .home-neko,.home-house{
    font-size:7vh;
    margin-top: -3vh;
  }
  .home-starsky{
    font-size: 3vh;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;    
  }
  .homeImg{
    height: 60vh;
  }
  .neko-house{
  font-size:35px;
  }
}
#about {
  background-color: rgba(218,221,237,1);
  position: relative;
}
.about-ctn {
  overflow: hidden;
  background-color: rgb(242, 243, 245);
  width: 87vw;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 70px;
}
.about-text-ctn {
  width: 80vw;
  margin: auto;
}
.about-text,.about-subtitle,.about-header{
  color: #363636;
}
.about-header{
  text-align: center;
  font-size: 20px;
  margin: 28px;
}
.about-text{
  white-space: pre-wrap;
  font-size: 18px;
  line-height: 2;
}
.abt-txt-title{
  color: #f1b93f;
  font-weight: 600;
  font-size: 20px;
  display: inline-block;
  margin-bottom: 8px;
  border-bottom: 2px solid #f1b93f;
  padding-right: 10px;
}
.textbox-left{
  display: flex;
  justify-content: center;
}
.logoImg{
  width: 150px;
  border-radius: 20px;
}
.aboutPaw{
  color: #f1b93f;
  font-size: 10px;
  margin-right: 3px;
}
.textbox-right{
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}
.about-text-box{
  display: flex;
  flex-direction: column;
}
/* Animation.css */
.about-header.animate__fadeInUp {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s
}
.about-line.animate__fadeInRight {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s
}

@media (min-width: 768px) {
  .textbox-right{
    margin-left: 3vw;
    padding: 15px;
    margin-top: 0px;
  }
  .about-ctn {
    width: 78vw;
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .about-text-ctn {
    width:60vw;
    margin: auto;
  }
  .about-title-ctn{
    display: flex;
    align-items: flex-end;
  }
  .about-header{
    color: #6E5858;
    font-size: 3.5vw;
    margin: 1vh;
  }
  .about-line {
    border-top: 0.5vh solid #f1b93f;
    margin-bottom: 6vh;
  }
  /* .about-line:nth-child(4){
    margin-top: 6vh;
  } */
  .about-text-box{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: auto;
    width: 90%;
  }
  .logoImg{
    width: 200px;
  }
  .arrowsSVG {
    margin: 4vh 0;
    width: 9vw;
    float: right;
  }
  .about-text{
    font-size: 18px;
  }
  .abt-txt-title{
    margin-top: 0px;
    font-size: 20px;
    padding-right: 10px;
    border-bottom: none;
  }
  .aboutPaw{
  font-size: 18px;
  margin: 0 5px;
  }
}
#awards{
  background: rgb(243,217,218);
  background: linear-gradient(0deg, rgba(243,217,218,1) 17%, rgba(218,221,237,1) 100%);
  overflow: hidden;
}
.awardImg-ctn{
  position: relative;
  overflow: hidden;
}
.awardsImg01,.awardsImg02 {
  display: block;
  max-width: 65%;
  width: auto;
  height: auto;
  margin: auto;
}
.awTextBox {
  background-color: white;
  width: 100%;
  text-align: center;
  padding: 10vh 0;
}
.awText {
  white-space: pre-wrap;
  line-height: 220%;
  font-size: 16px;
  color: #6E5858;
  /* no show before triggered by ScrollOut */
  opacity: 0;
}
.awardsBackground{
  width: 87vw;
  margin: auto;
  background-color: rgb(242, 243, 245);
  padding: 60px 0;
}
.awardsContent {
  background-color: #dadce8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0px 5px 10px #a0a7b1;
}
.pn {
  background-color: orange;
  display: inline-block;
  color: white;
  border-radius: 5px;
  padding: 0 10px;
  margin-top: 15px;
}
/* Color Sliding Block */
.awardBlockL,.awardBlockR {
  background-color: #0e3150;
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.awardBlockL2,.awardBlockR2 {
  background-color: #6dc9c8;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
/* visible while triggering by ScrollOut */
.awText.animate__fadeIn{
  opacity: 1;
}


@media (min-width: 768px) {
  .awardsContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 65vw;
    padding: 100px 0;
  }
  .awardsBackground{
  width: 78vw;
  padding: 150px 0 150px 0;
}
  .awText {
    font-size: 16px;
  }
  .awTextBox{
    margin:1%;
    max-width: 29vw;
    border-radius: 20px;
    box-shadow: 0px 5px 10px #8f96a0;
  }
  .awardsImg01,.awardsImg02,.awardImg-ctn {
    max-width: 15vw;
    min-width: 12vw;
    border-radius: 10px;
  }
}
#price {
  background: rgb(243,217,218);
}
.price-title{
  color: #746835;
  font-size: 24px;
}
.horizontal-line2{
  width: 60vw;
  border: 1px solid #0e3150;
  height: 1px;
  z-index: 1;
}
.price-ctn{
  background-color: rgb(242, 243, 245);
  width: 87vw;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
.price-line-ctn{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.price-line-info{
  font-size: 6vw;
  color: #0e3150;
  margin-right: 1vw;
  font-weight: 800;
}
.room-info,.room-detail,.room-price{
  color: #746835;
  white-space: pre-wrap;
  text-align: center;
  font-weight: 800;
  font-size: 18px;
}
.room-price{
  color: #f1a22c;
  font-size:30px;
  font-weight: 900;
}
.perday{
  font-size: 13px;
  color: #746835;
}
.room-detail{
  margin-bottom: 1vw;
  font-weight: 800;
  line-height: 28px;
}
.button{
  color: #746835;
  border-bottom: 2px solid #0e3150;
  margin-right: 1vw;
  padding: 1px;
  font-size: 18px;
}
.roomImg-ctn{
  display: flex;
  justify-content: center;
}
.room-img{
	height: auto;
  width: 80%;
  border-radius: 5px;
}
.price-line{
  width: 200px;
  border: 1px solid #cfc391;
  margin: auto;
}
.clicked{
  background-color:white;
  border-radius: 5px;
  color: #746835;
  box-shadow: 0px 3px 10px #aeb5be;
}
.price-right {
  background-color: white;
  box-shadow: 0px 5px 10px #aeb5be;
  width: 85%;
  margin: auto;
  padding: 20px 0;
  border-radius: 0 0 10px 10px;
}
.price-left{
  background-color: #ffffff;
  width: 85%;
  margin: auto;
  box-shadow: 0px 5px 10px #aeb5be;
  padding: 20px 20px 10px 20px;
  border-radius: 10px 10px 0 0;
}
.price-button{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 60vw;
  margin: 10px auto;
}

@media (min-width: 768px) {
  .price-button{
    margin: auto;
  }
  .button:hover{
    cursor: crosshair;
  }
  .room-img{
    border-radius: 15px;
    max-width: 25vw;
  }
  .price-title{
    font-size: 2.5vw;
    font-weight: 900;
    text-align: center;
  }
  .horizontal-line2{
    width: 20vw;
  }
  .price-ctn{
    width: 78vw;
    margin: auto;
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 100px;
  }
  .bottom-ctn{
    display:flex;
    justify-content: center;
  }
  .price-line-info{
    font-size: 2vw;
    margin-right: 1vw;
  }
  .room-info{
    margin: 2.5vh 0;
  }
  .room-detail,.room-info{
    font-size:18px;
    margin: 0;
  }
  .perday{
    font-size: 15px;
  }
  .button{
    font-size: 18px;
    margin: 0.2vh 0.5vw;
    padding: 5px;
  }
  .price-right {
    margin: 1vw;
    width: 30vw;
    border-radius: 10px;
  }
  .price-left{
    margin: 1vw;
    padding: 30px 20px 20px 20px;
    width: auto;
    border-radius: 10px;
  }
  .price-line{
    width: 250px;
    margin: 15px auto;
  }
  .room-detail-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 40px;
  }
  .clicked{
  box-shadow: 0px 5px 10px #aeb5be;
}
}
#facilities {
  background: linear-gradient(0deg, rgba(243,217,218,1) 0%, rgba(218,221,237,1) 12%, rgba(218,221,237,1) 89%, rgba(243,217,218,1) 100%);
  padding-bottom: 100px;
  display: block;
}
.facilities-ctn{
  background-color: rgb(242, 243, 245);
  width: 87vw;
  margin: auto;
  overflow: hidden;
  padding-top: 50px;
}
.facilitiesContent{
  width: 100%;
}
.title{
  font-size: 20px;
  text-align: center;
  color: #524217;
}
.facilitiesPaw{
  color: #524217;
  font-size: 15px;
  margin: 5px;
}
.facilities-title{
  display: flex;
  /* border: 1px solid red; */
  align-items: baseline;
  justify-content: center;
}
@media (min-width: 768px) {
  .facilities-ctn{
  width: 78vw;
  padding: 20vh 0;
  }
  .title{
    font-size: 35px;
    font-weight: 800;
  }
  .facilitiesPaw{
    font-size: 25px;
  }
}
.infotitle {
  font-size: 18px;
  margin: 5px;
  text-decoration: underline;
  padding: 2px;
  font-weight: 800;
}
.infoBlockContainer {
  margin: 10% 0;
  width: 70vw;
}
.infoImg {
  width: 60vw;
}
.block_center,.infoBlockContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.block_info{
  color:#444f59;
}
.blockText{
  font-size: 18px;
}
/* ScrollOut */
/* no show before triggered by ScrollOut */
.fLeft,.fRight{
  opacity: 0;
}

/* visible while triggering by ScrollOut */
.fLeft.animate__fadeInLeft,.fRight.animate__fadeInRight{
  opacity: 1;
}

@media (min-width: 768px){
  .block_container div:nth-child(even) img {
  order:2;
  }
  .infoBlockContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0;
    justify-content: center;
    width: auto;
    border-top: 2px solid #746835;
    border-bottom: 2px solid #746835;
  }
  .infoImg {
    width: 20vw;
  }
  .block_container{
    margin:5% 0;
  }
  .block_info{
    width:29vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .infotitle {
    font-size: 22px;
    margin-bottom: 20px;
    padding: 4px 10px;
    background-color: rgb(214, 214, 245);
    margin: 5px auto;
    border-radius: 5px;
    text-decoration: none;
  }
  .blockText{
    font-size: 18px;
    margin: 0 2vw;
  }
}
#important{
  background-color: white;
  padding-top: 50px;
  padding-bottom: 80px;
  overflow:hidden;
  z-index: -10;
  position: relative;
}
.txt_wrapper {
  font-size: 18px;
  white-space: pre-wrap;
  color: #444f59;
  padding:0 5vw;
  /* line-height: 30px; */
}
.eachDetail{
  margin-bottom: 20px;
}
.rotateY {
  transform: rotateY(180deg);
}
.blockYellow {
  position: absolute;
  height: 40vh;
  width: 200%;
  background-color: #FBDD7D;
  transform:rotate(-20deg);
  margin-left: -30%;
  box-shadow:0px 0px 8px 5px rgb(189, 187, 187); 
  z-index: -5;
}
.blockBlue {
  position: absolute;
  height: 40vh;
  width: 200%;
  background-color: #ffe2e2;
  transform:rotate(20deg);
  margin-left: -30%;
  z-index: -4;
  box-shadow:0px 0px 8px 5px rgb(189, 187, 187); 
}
.yellow01{
  top: 3%;
}
.blue01{
  top: 22%;
}
.yellow02{
  top: 38%;
  z-index: -4;
}
.blue02{
  top: 55%;
}
.yellow03{
  top:70%;
  z-index: -4;
}
.blue03{
  top: 88%;
  z-index: -4;
}
.visitTimeSVG {
  margin: 15px 0;
  width: 60vw;
}
.important_title{
  font-size: 40px;
}
.line_goThrough {
  border-top: 0.3px solid #d1cfcf;
  width: 100vw;
  height: 10px;
  position: relative;
  top: -1.6rem;
  z-index: -1;
}
.line_goThrough_btm{
  border-bottom: 0.3px solid #d1cfcf;
  width: 100vw;
  height: 10px;
  z-index: -1;
}
.blueCat{
  width: 25vw;
  margin: -7vw;
}
.important_wrapper{
  background-color: white;
  border: 0.3px solid #d1cfcf;
  box-shadow:0px 10px 10px grey;
  width:85vw;
  border-bottom: 0.3px solid #d1cfcf;
  margin: auto;
  overflow: hidden;
}
.catL_wrapper{
  position: relative;
}
.catL {
  position: absolute;
  top: -1.8rem;
  z-index: -2;
}
.importantContent .starsky{
  position: absolute;
  z-index: -1;
}
.h_subtitle{
  margin: 20px 0px 8px 15px;
  font-size: 24px;
}
.im_subtitle{
  color:rgb(117, 103, 103);
  font-weight: 800;
  padding: 0 5px;
  line-height: 24px;
  border: 1px solid rgb(182, 171, 171);
  display: inline-block;
  margin: 5px 0 3px 0;
}
.importantContent{
  position: relative;
}
.neko01{
  position: absolute;
  z-index: -3;
}
.neko02{
  position: absolute;
  top: 25%;
  z-index: -1;
}
.neko03{
  position: absolute;
  top: 50%;
  z-index: -1;
}
.neko04{
  position: absolute;
  top: 75%;
  z-index: -1;
}
.neko05{
  position: absolute;
  bottom: 0;
  z-index: -1;
}
hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* no show before triggered by ScrollOut */
.fLeft,.fRight{
  opacity: 0;
}
/* visible while triggering by ScrollOut */
.fLeft.animate__fadeInLeft,.fRight.animate__fadeInRight{
  opacity: 1;
}

@media (min-width: 768px){
  #important{
    padding-top: 100px;
    padding-bottom: 200px;
  }
  .blueCat{
    width: 20vw;
  }
  .important_title{
    font-size: 130px;
    font-weight: 500;
  }
  .line_goThrough{
    top:-4rem;
  }
  .blockYellow {
  height: 55vh;
  box-shadow:0px 0px 18px 18px rgb(189, 187, 187); 
  }
  .blockBlue {
    height: 55vh;
    box-shadow:0px 0px 18px 18px rgb(189, 187, 187); 
  }
  .h_subtitle{
    margin: 30px 0 15px 60px;
    font-size: 50px;
  }
  .txt_wrapper{
    font-size: 18px;
    line-height: 40px;
  }
  .visitTimeSVG {
    width: 22vw;
  }
  .visitTimewrapper{
    display: flex;
  }
  .visitTimeInfo{
    margin-left: 2vw;
    margin-top: 5vh;
  }
  .neko02,.neko05,.neko04{
    opacity: 0;
  }
  .important_wrapper{
    width:60vw;
    padding-bottom: 50px;
  }
  .line_goThrough_btm{
    opacity: 0;
  }
  .catL{
    margin-top: 5vh;
    width:30vw;
    margin-left: -4vw;
    margin-top: -2vh;
  }
  .importantContent .starsky{
    margin-left: 2vw;
    margin-top: 5vh;
  }
  .neko01{
    margin-top: 10vh;
  }
  .im_subtitle{
    padding: 2px 7px;
    margin: 10px 0 3px 0;
    font-size: 20px;
  }
  .eachDetail{
    margin-bottom: 30px;
  }
}
.nekoTxt {
  font-size: 16.5vw;
  color: #6dc9c8;
  margin: -5% auto;
}

.swiper-container.gallery {
  width: 100%;
  height: 350px;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide.gallery {
  /* background-size: cover; */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-next,.swiper-button-prev{
  color: rgb(77, 85, 189);
  /* background-color: white; */
  /* border-radius: 5px; */
}
.gallery-top {
  height: 80%;
  width: 100%;
}
.gallery-thumbs {
  box-sizing: border-box;
  padding: 10px 0;
  width: 100%;
  height: 14vh;
}
.gallery-thumbs .swiper-slide {
  height: 100%;
  opacity: 0.4;
}
.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}
.galleryWrapper {
  padding-bottom: 80px;
}

@media (min-width: 768px){
  .swiper-container.gallery {
    height: 60vh;
    width: 50vw !important;
  }
  .swiper-container{
    width: 50vw !important;
  }
  .galleryWrapper {
    padding-bottom: 200px;
  }
}

#album{
  background-color: #ffe2e2;
  padding-top: 100px;
  padding-bottom: 50px;
}
.album-ctn {
  width: 87vw;
  background-color: white;
  margin: auto;
  padding-top: 5px;
}
.album_title{
  font-size: 12vw;
  text-align: center;
  margin-top: 6vh;
  color: black;
}

@media (min-width: 768px){
  .album_title{
    font-size: 5rem;
    margin: 50px;
  }
  .album-ctn {
    width: 78vw;
    padding-top: 50px;
  }

}
nav{
  background-color: #ffe2e2;
  position: relative;
}
.navPaw{
  font-size: 15px;
  margin-right: 5px;
}
.bars {
  margin:0 10px;
  width: 20px !important;
  cursor: pointer;
  transition: all 2s;
  color: white;
}
.bars:hover{
  transform: rotate(1080deg);
}
.navItem {
  display: flex;
  align-items: center;
}
.nav-links{
  position: fixed;
  background-color: rgb(148, 200, 235);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  width: 100vw;
  height: 100vh;
  top:-150%;
  transition: all 1.1s;
  z-index: 900;
}
.nav-links.open{
  z-index: 900;
  line-height: 1.1;
  margin: auto;
  left: 0%;
  top:0%;
}
.nav-ctn{
  position: relative;
  width: 100vw;
  height: 100vh;
}
.navlinks-border{
  border: 5px solid white;
  background-color: rgb(148, 200, 235);
  height: 75vh;
  font-size: 20px;
  list-style: none;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5vw;
  width: 80vw;
  border-radius: 5px;
  /* centered the div */
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.navBtnBlock{
  background-color: rgb(148, 200, 235);
  height: 50vh;
  width: 100vw;
  position: absolute;
  bottom: 0;
  border-top: 5px solid white;
}
.navlinks-border>li:hover {
  cursor: crosshair;
  color: rgb(42, 117, 230);
}
ul{
  margin: 0;
}
.nav-links li {
  margin: 2.5vh;
}
.hambuger-ctn{
  width: 100%;
  position: fixed;  
  transition: 300ms transform ease-out;
  will-change: transform;
  top: -0;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  background: rgba(148, 200, 235);
}


/* ScrollOut */
/* scrolling down */
html[data-scroll-dir-y='1'] .hambuger-ctn {
  transform: translateY(-100%); 
}
/* scrolling up */
html[data-scroll-dir-y='-1'].hambuger-ctn  {
  transform: translateY(200%);
}

@media (min-width: 768px){
  .bars {
    right: 0;
    margin:0 30px;
    width: 25px !important;
  }
  .navPaw{
    font-size: 18px;
    margin-right: 8px;
  }
  .nav-links{
    font-size: 3.5vh;
    font-weight: 500;
  }
  .navlinks-border{
    width: auto;
    padding: 0 8vw;
  }
}

/* *{
  border: 0.5px solid red;
} */
/* Banner_L */
.banner{
  height: 10vh;
  overflow: hidden;
  position: relative;
}
.bannerImg {
  position: absolute;
  width: 100%;
  top:-300%;
  background-size:cover;
}

/* Banner_M */
.bannerM{
  width: 100%;
}
.bannerM_ctn{
  overflow: hidden;
  width: 87vw;
  margin: auto;
  height: 100px;
  position: relative;
}
.bannerM_background{
  width: 1000px;
  position: absolute;
  top: -300%;
  left: -10%;
}

 /* ThreeImg */
.threeImg{
  display: none;
}

@media (min-width: 768px) {
  /* Banner_L */
  .banner{
    height: 300px;
  }
  .bannerImg {
    top:-50%;
  }
  /* Banner_M */
  .bannerM_background{
    width: 150vw;
    margin: auto;
    top: -150%;
    left: -10%;
  }
  .bannerM_ctn{
    width: 78vw;
    height: 300px;
  }

  /* ThreeImg */
  .threeImg{
    display: inline;
    display: initial;
  }
  .threeImg-ctn{
    background-color: #dadce8;
    background: linear-gradient(0deg,rgba(183,188,212,1) 0%, rgba(218,220,232,1) 50%, rgba(183,188,212,1) 100%);
    width: 78vw;
    margin: auto;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    border: 20px solid rgb(242, 243, 245);
  }
  .threeImg-ctn img{
    width: auto;
    height: auto;
    max-width: 25%;
    margin: 1vw;
    display: block;
    border: 6px solid white;
    box-shadow: 5px 10px 10px grey;
    /* no show before triggered by ScrollOut */
    opacity: 0;
  }
  /* ScrollOut & Animation.css */
  .threeImg_individual.animate__flipInX {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
    /* visible while triggering by ScrollOut */
    opacity: 1;
  }
}
body, html {
  width: 100%;
  height: 100vh;
  border:0;
  background-color: #ffe2e2;
}
* {
  box-sizing: border-box;
  margin:0;
  padding:0;
}
.roboto {
  font-family: 'Roboto', sans-serif;
}
.abril-fatface {
  font-family: 'Abril Fatface', cursive;
}
/* chinese font */
.noto {
  font-family: 'Noto Sans TC', sans-serif;
}
.text_center{
  text-align: center;
}
.underline {
  text-decoration: underline;
}
@media (min-width: 768px) {

}
