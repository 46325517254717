#price {
  background: rgb(243,217,218);
}
.price-title{
  color: #746835;
  font-size: 24px;
}
.horizontal-line2{
  width: 60vw;
  border: 1px solid #0e3150;
  height: 1px;
  z-index: 1;
}
.price-ctn{
  background-color: rgb(242, 243, 245);
  width: 87vw;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
.price-line-ctn{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.price-line-info{
  font-size: 6vw;
  color: #0e3150;
  margin-right: 1vw;
  font-weight: 800;
}
.room-info,.room-detail,.room-price{
  color: #746835;
  white-space: pre-wrap;
  text-align: center;
  font-weight: 800;
  font-size: 18px;
}
.room-price{
  color: #f1a22c;
  font-size:30px;
  font-weight: 900;
}
.perday{
  font-size: 13px;
  color: #746835;
}
.room-detail{
  margin-bottom: 1vw;
  font-weight: 800;
  line-height: 28px;
}
.button{
  color: #746835;
  border-bottom: 2px solid #0e3150;
  margin-right: 1vw;
  padding: 1px;
  font-size: 18px;
}
.roomImg-ctn{
  display: flex;
  justify-content: center;
}
.room-img{
	height: auto;
  width: 80%;
  border-radius: 5px;
}
.price-line{
  width: 200px;
  border: 1px solid #cfc391;
  margin: auto;
}
.clicked{
  background-color:white;
  border-radius: 5px;
  color: #746835;
  box-shadow: 0px 3px 10px #aeb5be;
}
.price-right {
  background-color: white;
  box-shadow: 0px 5px 10px #aeb5be;
  width: 85%;
  margin: auto;
  padding: 20px 0;
  border-radius: 0 0 10px 10px;
}
.price-left{
  background-color: #ffffff;
  width: 85%;
  margin: auto;
  box-shadow: 0px 5px 10px #aeb5be;
  padding: 20px 20px 10px 20px;
  border-radius: 10px 10px 0 0;
}
.price-button{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 60vw;
  margin: 10px auto;
}

@media (min-width: 768px) {
  .price-button{
    margin: auto;
  }
  .button:hover{
    cursor: crosshair;
  }
  .room-img{
    border-radius: 15px;
    max-width: 25vw;
  }
  .price-title{
    font-size: 2.5vw;
    font-weight: 900;
    text-align: center;
  }
  .horizontal-line2{
    width: 20vw;
  }
  .price-ctn{
    width: 78vw;
    margin: auto;
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 100px;
  }
  .bottom-ctn{
    display:flex;
    justify-content: center;
  }
  .price-line-info{
    font-size: 2vw;
    margin-right: 1vw;
  }
  .room-info{
    margin: 2.5vh 0;
  }
  .room-detail,.room-info{
    font-size:18px;
    margin: 0;
  }
  .perday{
    font-size: 15px;
  }
  .button{
    font-size: 18px;
    margin: 0.2vh 0.5vw;
    padding: 5px;
  }
  .price-right {
    margin: 1vw;
    width: 30vw;
    border-radius: 10px;
  }
  .price-left{
    margin: 1vw;
    padding: 30px 20px 20px 20px;
    width: auto;
    border-radius: 10px;
  }
  .price-line{
    width: 250px;
    margin: 15px auto;
  }
  .room-detail-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 40px;
  }
  .clicked{
  box-shadow: 0px 5px 10px #aeb5be;
}
}