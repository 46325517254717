#home {
  background: rgb(243,217,218);
  background: linear-gradient(0deg, rgba(243,217,218,1) 83%, rgba(218,221,237,1) 100%);
  position: relative;
  height: 110vh;
  overflow: hidden;
}
.home-ctn{
  height: 100vh;
  display: flex;
  align-items: center;
}
.homeImg{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
}
.neko-house{
  color: #f4a3a4;
  font-size:5vh;
  display: inline-block;
}
.home-house{
  margin-top: -2vh;
}
.home-starsky{
  font-size: 1rem;
  color: #ffe2e2;
  background-color: #f4a3a4;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;   
}
.inverted-triangle{
  display: flex;
  flex-direction: column;
}

/* flashing inverted triangle for scroll down */
.fa-caret-down{
  animation: flash 1s infinite;
  /* border: 1px solid red; */
  margin: auto;
}
.fa-caret-down:nth-child(1){
  animation-delay: 1.3s;
  width: 25px;
  margin-top: 15px;
}
.fa-caret-down:nth-child(2){
  animation-delay: 1.6s;
  width: 25px;
  margin-top: -25px;
}
.fa-caret-down:nth-child(3){
  animation-delay: 1.9s;
  width: 25px;
  margin-top: -25px;
}
@keyframes flash {
  from {opacity: 0;}
  to {opacity: 1;}
}
/* scrollOut */
.home-house[data-scroll="in"] {
 animation-delay: 0.5s
}
.home-starsky[data-scroll="in"] {
 animation-delay: 1s
}
.inverted-triangle[data-scroll="in"] {
 animation-delay: 1.5s
}

@media (min-width: 768px) {
  .home-neko,.home-house{
    font-size:7vh;
    margin-top: -3vh;
  }
  .home-starsky{
    font-size: 3vh;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;    
  }
  .homeImg{
    height: 60vh;
  }
  .neko-house{
  font-size:35px;
  }
}