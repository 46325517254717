#album{
  background-color: #ffe2e2;
  padding-top: 100px;
  padding-bottom: 50px;
}
.album-ctn {
  width: 87vw;
  background-color: white;
  margin: auto;
  padding-top: 5px;
}
.album_title{
  font-size: 12vw;
  text-align: center;
  margin-top: 6vh;
  color: black;
}

@media (min-width: 768px){
  .album_title{
    font-size: 5rem;
    margin: 50px;
  }
  .album-ctn {
    width: 78vw;
    padding-top: 50px;
  }

}